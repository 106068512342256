.task-list {
	.task-item {
		&.headline {
			.taskLineWrap:hover {
				background: transparent;
			}
			.taskLineWrap {
				border-left-color: $light;
				* {
					color: $grey;
				}
			}
			.lineitem {
				&.first {
					padding-top: 0 !important;
				}
				> span + i {
					margin-left: 6px;
					color: $blue;
				}
				span {
					cursor: pointer;
					@include font-size(14);
				}
				span.active {
					color: $blue;
					font-weight: 500;
				}
			}
		}
	}
}

.taskList, .inDocument {
	.taskItem {
		padding-right: 12px;
		.taskInfoBar {
			margin-left: 0;
			padding-left: 0;
			margin-bottom: 0;
			list-style: none;
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
			cursor: pointer;
			border-bottom: 1px solid $border;
			&:hover {
				background: $bgbox;
				.arrow {
					color: $blue;
				}
				.price {
					color: $black;
				}
			}
			li {
				&.arrow {
					width: 24px;
					text-align: center;
					@include font-size(16, 1.5);
					margin-right: 12px;
				}
				&.title {
					width: 250px;
				}
				&.quantity {
					margin-left: 12px;
					text-align: center;
				}
				&.price {
					margin-left: auto;
					text-align: left;
					font-weight: bold;
					color: $dark;
				}
				&.unit {
					margin-left: 6px;
					@include font-size(12, 2);
				}
				input {
					height: 20px;
				}
			}
		}
	}
}

.fileList {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
	li i {
		cursor: pointer;
		&:hover {
		}
	}
}

.inDocument {
	.input-group {
		padding-right: 16px;
		input.fullPriceRender {
			height: 20px;
			font-size: 16px;
			margin-left: auto;
			font-weight: bold;
			text-align: right;
		}
		select {
			height: 20px;
			width: 100%;
			font-size: 14px;
		}
	}
	.taskItem {
		ul.taskInfoBar {
			li {
				padding-top: 6px;
				padding-left: 3px;
				padding-right: 3px;
				input {
					max-width: 100%;
					width: 100%;
					height: 20px;
					font-size: 14px;
				}
				&.quantity {
					width: 90px;
				}
				&.unit {
					width: 100px;
				}
				&.title {
					width: 400px;
				}
				&.final_price {
					input {
						text-align: right;
					}
				}
			}
		}
	}
}

.appView.tasks, .appView.customers {
	.taskLineWrap {
		transition: .5s all ease 0s;
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $border;
		&:hover, &.active {
			background: $bgbox;
		}
		.d-flex {
			width: 100%;
		}
		.lineitem {
			transition: .3s all ease 0s;
			max-width: 100%;
			padding: 8px 6px 9px;
			min-width: 32px;
			display: inline-flex;
			align-self: center;
			.incNumber {
				@include font-size(11);
				position: relative;
				z-index: 1;
				width: 16px;
				display: block;
				height: 16px;
				text-align: center;
				transform: translateY(-7px);
				background: $purple2;
				line-height: 14px;
				border-radius: 100%;
				left: 0;
				top: 0;
				white-space: nowrap;
			}
			&.l-checkbox {
				padding-left: 12px;
				padding-top: 3px;
				padding-right: 6px;
			}
			&.l-subtasks {
				padding-left: 12px;
				padding-right: 12px;
				width: 40px;
				text-align: center;
				justify-content: center;
			}
			&.l-attachments {
				padding-left: 12px;
				padding-right: 12px;
				width: 50px;
				text-align: center;
				justify-content: center;
				display: none;
				@media #{$large}{
					display: inline-flex;
				}
			}
			&.l-comments {
				padding-left: 12px;
				padding-right: 12px;
				width: 60px;
				text-align: center;
				justify-content: center;
				display: none;
				@media #{$large}{
					display: inline-flex;
				}
			}
			&.l-times {
				padding-left: 12px;
				padding-right: 12px;
				width: 60px;
				text-align: center;
				justify-content: center;
				display: none;
				@media #{$large}{
					display: inline-flex;
				}
			}
			&.l-customer {
				@include font-size(12);
				width: 140px;

				display: none;
				@media #{$xlarge}{
					display: inline-flex;
				}
				>span{
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				&.projects {
					display: none;
				}
				@media #{$hd}{
					&.projects {
						display: inline-flex;
					}
					width: 200px;
				}
			}
			&.l-avatar {
				text-align: center;
				padding-left: 32px;
				padding-right: 12px;
				min-width: 76px;
			}
			&.l-number {
				padding-left: 12px;
				padding-right: 12px;
				width: 105px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
			}
			&.l-title {
				width: 320px;
				font-weight: 500;
				@include font-size(14, 1.8);
				padding-right: 12px;
				display: inline-flex;
				align-items: center;
				&.l-doctitle {
					width: 440px;
				}
				i {
					margin-right: 6px;
				}
				@media (min-width: 1900px){
					width: 480px;
				}
			}
			&.l-email {
				width: 200px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				@include font-size(14, 1.8);
				button {
					white-space: nowrap;
					text-overflow: ellipsis;;
					overflow: hidden;
					max-width: 100%;
				}
			}
			&.l-price {
				width: 100px;
				text-align: right;
				padding-right: 12px;
			}
			&.l-prio {
				width: 50px;
				text-align: center;
				justify-content: center;
			}
			&.l-state {
				width: 40px;
				text-align: center;
				justify-content: center;
			}
			&.l-unit {
				text-align: left;
				width: 100px;
				.badge {
					width: 100%;
				}
			}
			&.l-id{
				display: none;
				@media (min-width: 1900px){
					display: inline-flex;
				}
				@include font-size(14);
				align-items: center;
				width: 70px;
				justify-content: center;
			}
			&.l-hours {
				width: 75px;
				align-items: center;
			}
			&.l-date {
				width: 100px;
				@include font-size(14);
				&.due {
					color: $red;
					font-weight: 500;
				}
				&.lastChange {
					width: 78px;
					@include font-size(12);
					color: $grey;
					display: none;
					@media (min-width: 1900px){
						display: block;
					}
				}
			}
			&.l-action {
				align-self: flex-end;
				padding-right: 24px;
				padding-left: 24px;
				text-align: center;
				justify-content: center;
				.fa-x {
					display: inline-block;
				}
			}
			&.last {
				padding-right: 16px;
			}
		}
	}
	h6.editLine {
		display: inline-block;
		padding-left: 12px;
		margin-bottom: 0;
	}
	span.breadrumb-item {
		font-size: 14px;
		cursor: pointer;
		&:hover {
			color: $purple;
		}
		&:last-child {
			font-weight: 600;
		}
	}
	.subTasks {
		.taskWrap {
			.taskLineWrap {
				.lineitem {
					&.l-title {
						text-overflow: initial;
						overflow: visible;
						white-space: normal;
					}
				}
			}
		}
	}
	.taskAttachments, .timeSheet {
		.card-header {
			border-radius: 0;
			min-height: 48px;
		}
		.timeSheetList {
			table.table-hover {
				tr:hover {
					td {
						box-shadow: none;
						background: $purple2;
					}
				}
			}
		}
	}
	.tasks > .card {
		box-shadow: 0px 3px 6px #00000029;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 24px;
		border: 0;
		.taskLineWrap {
			background: $bgbox;
			.fa-x {
				display: inline-block;
			}
		}
		.card-header-inline {
			border: 0;
			padding-top: 9px;
			padding-bottom: 9px;
			h6.editLine {
				margin-top: 4px;
				display: block;
			}
		}
		.card {
			@include clear();
			border: 0;
			border-top: 0;
			margin-bottom: 12px;
			.card-body {
				border: 0;
				border-radius: 0 0 $br $br;
				border-top: 0;
				margin-bottom: 12px;
				width: 100%;
			}
		}
		.taskContent {
			> .card {
				> .card-header {
					border-radius: 0;
				}
			}
		}
	}
	.taskComments {
		.list-group {
			border: 0;
			.list-group-item {
				border: 0;
				padding: 0;
				margin-bottom: 6px;
				&:last-child {
					border-bottom: 0;
				}
				span.inline-comment {
					@include font-size(14);
					padding-left: 8px;
					padding-right: 8px;
				}
				.infoLine{

					margin-bottom: 3px;
				}
				.isComment{
					padding: 4px 12px;
					min-width: 120px;
					border-radius: 16px;
					margin-bottom: 6px;
					p:last-child{
						margin-bottom: 0;
					}
				}
				&.own {
					.isComment {
						background: $purple;
						color: $light;
						float: left;

						* {
							color: $light;
						}

					}
				}
				&.other{
					.infoLine{
						justify-content: flex-end;
					}
					.isComment{
						background: #eee;
						color: $base;
						float: right;
						*{
							color: $base;
						}
					}
				}
				&.onlyState{
					margin-bottom: 2px;
					img.sketchAvatar{
						width: 16px; height: 16px;
					}
					.infoLine{
						margin-bottom: 0;
					}
					@include font-size(12, 1);
					*{
						color: $grey;
						line-height: 1;
						font-size: 12px !important;
					}
				}
			}
		}
		.inlineCommentWrap {
			@include clear();
			.infoLine {
				width: 100%;
				clear: both;
				display: flex;
				align-items: center;
				small {
					display: inline-flex;
					color: $grey1;
					b {
						color: $base;
						font-weight: 600;
						line-height: 2.23;
					}
					&.avatar {
						display: inline-flex;
						align-items: center;
					}
					.sketchAvatar {
						position: relative;
						top: -2px;
						margin-right: 5px;
					}
				}
				&:hover {
					small {
						color: $base;
					}
				}
			}
		}
		.infoBox {
			min-width: 175px;
			text-align: right;
		}
	}
	.taskDetails {
		.topInfos {
			.col {
				@include font-size(14);
				label.color {
					@include font-size(16);
				}
			}
		}
		.rightCol {
			span {
				p {
					margin-bottom: 0;
					&:last-child {
						margin-bottom: 24px;
					}
				}
			}
		}
		.taskDesc {
			+ .ck {
				.ck.ck-toolbar {
					border-color: transparent;
				}
			}
			&.on-blur {
				+ .ck {
					.ck.ck-content.ck-editor__editable {
						padding-left: 0;
						border-color: rgba($border, 0);
					}
					.ck.ck-toolbar__items {
						opacity: 0;
						height: 0;
					}
					.ck.ck-content {
						min-height: 156px;
						border-color: transparent;
					}
				}
			}
			&.on-focus {
				+ .ck {
					.ck.ck-toolbar {
						border-color: $border;
					}
					.ck.ck-content.ck-editor__editable {
						padding-left: 0.6em;
						border-color: rgba($border, 1);
						box-shadow: none;
					}
					.ck.ck-toolbar__items {
						opacity: 1;
						height: auto;
					}
				}
			}
		}
		input.form-control.form-control-sm.form-control-plaintext.small {
			width: 50px;
			display: inline-block;
		}
	}
	.tabNav {
		.incNumber {
			@include font-size(11);
			position: relative;
			z-index: 1;
			width: 16px;
			display: inline-block;
			height: 16px;
			text-align: center;
			transform: translateY(-7px);
			background: $purple2;
			line-height: 14px;
			border-radius: 100%;
			left: 0;
			top: 0;
			white-space: nowrap;
		}
	}
}

button.checkCircleBtn.btn.btn-sm {
	font-size: 8px;
	border-radius: 100%;
	width: 24px;
	height: 24px;
	padding: 0;
	text-align: center;
}

body #wrapper .hoverShowInput {
	background: transparent;
	border: 1px solid transparent;
	padding-top: 0;
	padding-bottom: 0;
	min-height: 0;
	&:hover {
		background: $bg;
		border: 1px solid transparent;
	}
}

.blurDesc {
	+ .ck {
		margin-left: 12px;
		margin-right: 12px;
		@media #{$large}{
			margin-left: 24px;
			margin-right: 24px;
		}
		.ck.ck-toolbar {
			border-color: transparent;
		}
	}
	&.on-blur {
		+ .ck {
			.ck.ck-content.ck-editor__editable {
				padding-left: 0;
				border-color: rgba($border, 0);
				max-height: 70px;
				overflow: hidden;
				min-height: 0;
			}
			.ck.ck-toolbar__items {
				opacity: 0;
				height: 0;
			}
			.ck.ck-content {
				min-height: 156px;
				border-color: transparent;
			}
			.ck.ck-editor__top.ck-reset_all {
				display: none;
			}
		}
	}
	&.on-focus {
		+ .ck {
			margin-top: 24px;
			.ck.ck-toolbar {
				border-color: $border;
			}
			.ck.ck-content.ck-editor__editable {
				padding-left: 0.6em;
				border-color: rgba($border, 1);
				box-shadow: none;
			}
			.ck.ck-toolbar__items {
				opacity: 1;
				height: auto;
			}
		}
	}
}

.modal-body {
	embed {
		width: 100%;
		height: auto;
	}
}

.prioSetter {
	button.btn.btn-outline-secondary:hover {
		border-color: #6c757d !important;
		span, * {
			color: $light;

		}
	}
}
